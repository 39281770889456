<template>
  <div class="login-page">
      <div class="logo" title="返回首页">
      <img src="@/assets/xunilogo.png"  />
    </div>
    <div class="bg"></div>
    <div class="ikon">
         <Row>
        <Col span="12"><img src="../../assets/ikon.png" /></Col>
        <Col span="12" style="background:#fff;border-top-right-radius:30px;border-bottom-right-radius:30px;">
        <div style="width:70%;margin:110px auto">
        <el-form ref="form" :model="form" label-width="90px" size="small">
            <el-form-item label="手机号">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="登陆密码">
                <el-input v-model="form.password" type="password"></el-input>
            </el-form-item>
            <el-form-item label="手机验证码">
                <el-input v-model.trim="form.code" maxlength="8"  style="width:105px;float:left"></el-input>
                <el-button type="primary" :disabled="true" v-if="!form.password || !form.phone" class="btt">获取验证码</el-button>
                <el-button type="primary" v-else :disabled="code_show" @click="logbtn"  class="btt">{{coded}}</el-button>
            </el-form-item>
            <el-form-item label-width="0" style="textAlign:right">
              <!-- <p style="float:left;fontSize:10px"><el-checkbox v-model="checked" />阅读并同意<el-link :underline="false" style="color:blue" @click='dialogVisible=true'>用户协议、隐私声明</el-link></p> -->
                <el-link @click="resetpwd" :underline="false" style="float:rihgt;fontSize:10px;padding-left:50px">忘记密码?</el-link>
          </el-form-item>
           <el-form-item size="large">
          <el-button type="primary" style="width:80%"  @click="login">登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 录</el-button>
        </el-form-item>
        </el-form>
        </div>
            </Col>
    </Row>
    </div>
  </div>
</template>

<script>
// import imgcode from '@/components/imgcode'
export default {
  name: "Login",
  components: { 
  },
  data() {
    // var validatorPhone = function (rule, value, callback) {
    //   if (value === '') {
    //     callback(new Error('手机号不能为空'))
    //   } else if (!/^1\d{10}$/.test(value)) {
    //     callback(new Error('手机号格式错误'))
    //   } else {
    //     callback()
    //   }
    // };
    return {
      dialogVisible:false,
      checked:false,
        code_show:false,
        coded:"获取验证码",
        times:60,
        form:{
            phone:"",
            password:"",
            code:""
        },

    };
  },
   mounted(){
    if (this.$route.query.token!=undefined) {
       sessionStorage.setItem('keys',this.$route.query.token)
     }
     var key=sessionStorage.getItem('keys')
  if(key!=null){
    this.$router.push("/homes/merchant_order")
  }
   },
  methods: {
    reading(){
      this.dialogVisible=false
      this.checked=true
    },
    //登录
      logbtn(){
        if(this.form.phone.length!=11||this.form.phone.length==''){
          this.$message({
            message:"请输入正确的手机号",
            type:"warning"
          })
          return false;
        }
          this.coded="发送中"
      var bo_clock = setInterval( ()=> {
          this.code_show=true
            this.times--;
            this.coded =this.times + 's';
            if (this.times < 0) {
            clearInterval(bo_clock);
            this.coded = '获取验证码';
            this.time = 60;
            this.code_show=false
            }
            },1000)
            this.$axios.post("/supplier/login.sms",{phone:this.form.phone})
             .catch(()=>{
          this.coded = '获取验证码';
              this.$message({
              message: '发送失败',
              type: 'error',
             center:'true'})
        })
            .then(res=>{
              if (res.data.code!=20000) {
              this.$message({
                type:'warning',
                message:res.data.message
              })}
        })
      },
      resetpwd(){
        this.$router.push("/resetpwd")
      },
      login(){
        this.$axios.post('/supplier/login',{code:this.form.code,phone:this.form.phone,password:this.form.password}).then((res)=>{
          if (res.data.code==20000) {
            sessionStorage.setItem('keys',JSON.stringify(res.data.data));
            this.$router.push("/homes/merchant_order")
          }else{
            this.$message({
              type:"danger",
              message:res.data.message
            })
          }
        })
      }

      
}

};

</script>

<style  scoped >
.left{width:60%}
.ikon{width:50%;overflow: hidden;height:500px;border-radius: 30px;margin:auto;align-items: center; top: 0;bottom: 50px;left: 0;right: 0;position: absolute;}

  .ikon img {
    width: 100%;
    height: 500px;
  } 


.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
}
 .logo img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/Login_bg.png) no-repeat;
    background-size:1920px 670px;
    opacity: 0.9;
    z-index: -10;
  }
.btt{
  width: 100px !important;
}
.agreement{
    height: 400px;
    overflow: auto;
  } 
</style>