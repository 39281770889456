import Vue from 'vue'
import VueRouter from 'vue-router'
import Homes from '@/views/Home.vue'
import Login from '@/views/Login/Login.vue'
import Resetpwd from '@/views/Login/Resetpwd.vue'
// import { createRouter, createWebHistory } from 'vue-router'
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/jump',
    name: 'Gysptht',
    component: () => import( '@/views/Login/Gysptht.vue')
  },
{
  path: "/",
  name:"Login",
  component:Login,
},{
  path: "/resetpwd",
  name:"Resetpwd",
  component:Resetpwd,
},

  {
    path: '/homes',
    name: 'Homes',
    redirect:"/homes/merchant_order",
    component: Homes,
    children:[
    {
      path: '/homes/merchant_order',
      name: 'Merchant_order',
      component: () => import( '@/views/Goods_manage/merchant_order.vue')
    },
    {
      path: '/homes/goods_config',
      name: 'Goods_config',
      component: () => import( '@/views/Goods_manage/goods_config.vue')
    },
    {
      path: '/homes/artificial',
      name: 'Artificial',
      component: () => import( '@/views/Goods_manage/artificial_recharge.vue')
    },
    {
      path: '/homes/goods_order',
      name: 'Goods_order',
      component: () => import( '@/views/Goods_manage/goods_order.vue'),

    }, 
    {
      path: '/homes/breadcrumb',
    name: 'Breadcrumb',
    component: () => import( '@/views/Goods_manage/breadcrumb.vue')},
    {
      path: '/homes/goods_list',
      name: 'Goods_list',
      component: () => import( '@/views/Goods_manage/goods_list.vue'),
    },
    {
      path: '/homes/edit',
      name: 'Edit',
      component: () => import( '@/views/Goods_manage/edit.vue'),
    },
    {
      path: '/homes/order_list',
      name: 'Order_list',
      component: () => import( '@/views/Order_manage/order_list.vue'),
    },
    {
      path: '/homes/merchant_goods',
      name: 'Merchant_goods',
      component: () => import( '@/views/Goods_else/merchant_goods.vue'),
    },
    {
      path: '/homes/bread',
      name: 'Bread',
      component: () => import( '@/views/Goods_else/three_tree.vue'),
    },
    {
      path: '/homes/addgoods',
      name: 'Addgoods',
      component: () => import( '@/views/Goods_else/addgoods.vue'),
    },
    {
      path: '/homes/goodsconfig',
      name: 'Goodsconfig',
      component: () => import( '@/views/Goods_else/goods_change.vue'),
    },
    {
      path: '/homes/account',
      name: 'Account',
      component: () => import( '@/views/Account_center/index.vue'),
    },
    {
      path: '/homes/billdown',
      name: 'Billdown',
      component: () => import( '@/views/Account_center/bill_down.vue'),
    },
    {
      path: '/homes/daybill',
      name: 'Daybill',
      component: () => import( '@/views/Account_center/day_bill.vue'),
    },
    {
      path: '/homes/monbill',
      name: 'Monbill',
      component: () => import( '@/views/Account_center/mon_bill.vue'),
    }]
    
    

  },
  
  
]

const router = new VueRouter({
  // mode: 'history',
  // base: '/supplier',
  routes
})

export default router
