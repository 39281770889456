import axios from 'axios';
// import qs from 'qs';
import router from '../router'

axios.defaults.baseURL = "http://shop.server.chengmeiyoupin.com/api.s";//测试



// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'

  // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    var key=sessionStorage.getItem('keys')
  if(key!=null){
  var token=JSON.parse(key) 
    config.headers['SER-TOKEN'] = token.token;
  }

    // axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

// 响应拦截------------------------------------------------
axios.interceptors.response.use(res => {
  if (res.data.code== 50003) {
    sessionStorage.removeItem('key');
  router.push({path:"/"}) 
  return false
    }else{
      return res
    }
}), error => {
   return Promise.reject(error);
}
export default axios