<template>
  <div style="background:#fff">
      <div class="header">
          <div class="left">
            <img src="@/assets/xunilogo.png"  />
          </div>
          <div class="right">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" style="padding-top:20px;color:black">
              <el-menu-item index="1" style="color:blue" @click="jump">供应商平台后台</el-menu-item>
              <el-submenu index="2">
                <template slot="title" style="color:black!important">{{name}}</template>
                <el-menu-item index="2-1" @click="accmanage=true">账户管理</el-menu-item>
                <el-menu-item index="2-2" @click="outlogin">退出登录</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
      </div>
      <el-dialog
        title="店铺账户设置"
        :visible.sync="accmanage"
        width="30%"
        center>
        <div style="textAlign:center;">
          商户名称：{{name}}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="accmanage = false;loginpwd=true;close()" type="primary">修改登录密码</el-button>
          <el-button type="primary" @click="accmanage = false">取消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="修改登录密码"
        :before-close="close"
        :visible.sync="loginpwd"
        width="30%">
        <div style="padding-left:100px">
          <el-form :model="pwd" :rules="rules" ref="pwd" label-width="100px">
            <el-form-item label="当前密码" prop="old_password">
              <el-input v-model="pwd.old_password" type="password" style="width:65%;float:left" clearable show-password placeholder="请输入当前密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
              <el-input v-model="pwd.new_password" placeholder="8到20个字，包含字母和数字" style="width:65%;float:left" type="password" clearable show-password></el-input>
            </el-form-item>
            <el-form-item label="重复新密码" prop="password_confirm">
              <el-input v-model="pwd.password_confirm" placeholder="8到20个字，包含字母和数字" style="width:65%;float:left" type="password" clearable show-password ></el-input>
            </el-form-item>
            <el-form-item style="textAlign:left">
              <el-button @click="accmanage = true;loginpwd=false" type="primary">返回上一页</el-button>
              <el-button type="primary" @click="changepwd('pwd')">确认修改</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="accmanage = true;loginpwd=false" type="primary">返回上一页</el-button>
          <el-button type="primary" @click="changepwd">确认修改</el-button>
        </span> -->
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.pwd.new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules:{
        old_password:[{
          required: true, message: '请输入当前密码', trigger: 'blur' }],
        new_password:[
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/, message: '密码为8到20个字，包含字母和数字' } 
        ],
        password_confirm: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]

      },
      pwd:{
        old_password:"",
        new_password:"",
        password_confirm:""
      },
      loginpwd:false,
      accmanage:false,
      activeIndex:"1",
      name:""
 } },
 mounted(){
   var key=JSON.parse(sessionStorage.getItem('keys'))
   this.name=key.phone
 },
 methods: {
   jump(){
     this.$axios.post("/supplier/multipoint").then(res=>{
       if (res.data.code==20000) {
         window.open('http://newshop.chengmeiyoupin.com/#/?token='+JSON.stringify(res.data.data),"_blank")//测试
        //  window.open('https://haobuhao.chengmeiyouxuan.com/supplier.platform/?token='+JSON.stringify(res.data.data),"_blank")//正式
        //  window.open('https://haotian.chengmeiyouxuan.com/supplier.platform/?token='+JSON.stringify(res.data.data),"_blank")//备份
       }
     })
   },
   outlogin(){
     this.$confirm('您确定要退出登陆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center:true
        }).then(() => {
          this.$axios.post('/supplier/logout')
          .then(res=>{
            if(res.data.code==20000){
              sessionStorage.removeItem('keys')
              this.$router.push('/')
            }
          })
        })
   },
   changepwd(formName){
     this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post("/supplier/admin/update.password",this.pwd).then(res=>{
              if (res.data.code==20000) {
                this.$message({
                  type:'success',
                  message:res.data.message
                })
                this.loginpwd=false
                sessionStorage.removeItem('keys')
                this.$router.push('/')
              }else{
                this.$message({
                  type:'warning',
                  message:res.data.message
                })
              }
              
            })
          }
     })
   },
   close(done){
     this.$refs['pwd'].resetFields();
     done()
   },
 },
}
</script>

<style lang="less" scoped>
.header{width: 1500px;margin: 0 auto;height: 80px; }
.left{float: left;width: 64px;height: 80%;margin: 10px 0 0 30px;
img{width:100%;height: 100%;}}
.right{float: right;height: 80px;line-height: 80px}
.el-menu.el-menu--horizontal {border-bottom: none;}
/* 点击出来的下划线进行隐藏 */
.el-menu-item.is-active {border-bottom: #EBEEF5 !important;color: rgba(0, 0, 0);}
/* 点击出来的submenu下划线进行隐藏 */
::v-deep .el-submenu.is-active .el-submenu__title {
border-bottom: #EBEEF5 !important;

}
.el-menu--horizontal>.el-submenu /deep/ .el-submenu__title{
  color:black !important
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  color: black;text-align: center;
}
.el-menu--popup /deep/  {min-width: 140px !important;}
</style>

