<template>
  <div class="login-page">
      <div class="logo" title="返回首页" @click="toindex()">
      <img src="@/assets/xunilogo.png" />
    </div>
    <br />
    <el-card class="box-card">
      <h2 style="text-align:left;padding:10px 0 0 10px">找回密码</h2>
        <div style="width:600px;margin:110px auto">
        <el-form ref="form" :model="form" label-width="100px" size="small" :rules="rule" >
            <el-form-item label="手机号：" prop="phone">
                <el-input v-model="form.phone" style="width:200px;float:left" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="code">
                <el-input v-model="form.code" maxlength="6" placeholder="请输入手机验证码" style="width:200px;float:left"></el-input>
                <el-button type="primary" style="float:left;marginLeft:10px"  @click="logbtn">{{coded}}</el-button>
            </el-form-item>
             <el-form-item label="重置密码：" prop="pwd">
                <el-input v-model="form.pwd" type="password" style="width:200px;float:left" placeholder="请输入新密码" show-password></el-input>
            </el-form-item>
           <el-form-item>
          <!-- <el-button type="primary" disabled v-if="form.phone=='' && form.code=='' && form.pwd==''" style="width:150px;float:left">确认修改</el-button> -->
          <el-button type="primary"  style="width:150px;float:left" @click="changepwd('form')">确认修改</el-button>
        </el-form-item>
        </el-form>
        </div> 
    </el-card>
    <!-- <div class="restpwd">
        <h2 style="text-align:left;padding:10px 0 0 10px">找回密码</h2>
        <div style="width:70%;margin:110px auto">
        <el-form ref="form" :model="form" label-width="100px" size="small" :rules="rule" >
            <el-form-item label="手机号：" prop="phone">
                <el-input v-model="form.phone" style="width:55%;float:left" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="code">
                <el-input v-model="form.code" maxlength="6" placeholder="请输入手机验证码" style="width:55%;float:left"></el-input>
                <el-button type="primary" style="float:left;marginLeft:10px"  @click="logbtn">{{coded}}</el-button>
            </el-form-item>
             <el-form-item label="重置密码：" prop="pwd">
                <el-input v-model="form.pwd" type="password" style="width:55%;float:left" placeholder="请输入新密码" show-password></el-input>
            </el-form-item>
           <el-form-item>
          <el-button type="primary" disabled v-if="form.phone=='' || form.code=='' || form.pwd==''" style="width:50%;float:left">确认修改</el-button>
          <el-button type="primary" v-else style="width:50%;float:left" @click="changepwd('form')">确认修改</el-button>
        </el-form-item>
        </el-form>
        </div> 
    </div>-->
  </div>
</template>

<script>
export default {
data() {
    let validPassword=(rule,value,callback)=>{
let reg= /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$/
      if(!reg.test(value)){callback(new Error('密码必须是由4-20位字母+数字组合'))
      }else{
          callback()
      }
  };
    let validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
        times:60,
        user:"",
        code_show:false,
        coded:"获取验证码",
        checked:false,
        form:{
            phone:"",
            code:"",
            pwd:""
            },
            rule:{
                phone:[{required:true,validator: validatorPhone,trigger:'blur'}],
                code:[{required:true,message: '请输入验证码', trigger: 'blur'}],
                pwd:[{required:true,message: '请输入新密码', trigger: 'blur'},
                { validator: validPassword, trigger: 'blur' }],
                }
    }
},
methods:{
    toindex(){
        this.$router.push({name:"Login"})
    },
    logbtn(){
        if(this.form.phone==""){
            this.$message({
          message:"请输入手机号",
          type: 'error',
          center:'true'
        })
            return false
        }
         this.coded="发送中"
      var bo_clock = setInterval( ()=> {
          this.code_show=true
            this.times--;
            this.coded =this.times + 's';
            if (this.times < 0) {
            clearInterval(bo_clock);
            this.coded = '获取验证码';
            this.time = 60;
            this.code_show=false
            }
            },1000)
            this.$axios.post("/supplier/reset.password.sms",{phone:this.form.phone}).then((res)=>{
              console.log(res)
            })
    },
    changepwd(formName){
        this.$refs[formName].validate((valid) => {
            if(valid){
                this.$axios.post("/supplier/reset.password",{phone:this.form.phone,code:this.form.code,new_password:this.form.pwd}).then(res=>{
                  if (res.data.code==20000) {
                    this.$message({
                      type:"success",
                      message:res.data.message
                    })
                    this.$router.push({path:"/"})
                  }else{
                    this.$message({
                      type:"warning",
                      message:res.data.message
                    })
                  }
                })
            }else{
                return false
            }
        })
}}
}
</script>

<style scoped>
.login-page{background: #F2F3FA;height: 100vh;}
 .box-card {
    width: 1000px;
    margin: 0 auto;
  }
body{
  background: #F2F3FA;
}

  .logo {
  width: 60px;
  margin: 0 0 0 100px;
    }

 .logo img {
  padding-top: 20px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .lab {
      display: inline-block;
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
      text-align: right;
    }
    .login_text {
        display: inline-block;
      width: 240px;
      height: 30px;
      border: 1px solid gray;
      outline: none;
      text-indent: 10px;
      padding-left: 0px;
      font-size: 18px;

    }
</style>