<template>
  <div style="background:#F2F3FA;width:100%;height:100vh">
    <Tophead></Tophead>
<Layout class="width">
            <Layout>
                <Sider >
                    <Menu :theme="dark" ref="side_menu" :open-names="openname"  width="auto" :active-name="listname" @on-select="change">
                <Submenu name="1">
                    <template slot="title">
                        商品管理
                    </template>
                    <MenuItem name="1-1" to="/homes/merchant_order" >商品仓库</MenuItem>
                    <MenuItem name="1-2" to="/homes/goods_order">商品列表</MenuItem>
                    <MenuItem name="1-3" to="/homes/goods_config">商户商品配置表</MenuItem>
                    <MenuItem name="1-4" to="/homes/artificial">人工代充</MenuItem>
                </Submenu>
                <Submenu name="2">
                    <template slot="title">
                        订单管理
                    </template>
                    <MenuItem name="2-1" to="/homes/order_list">订单列表</MenuItem>
                </Submenu>
                <Submenu name="3">
                    <template slot="title">
                        账户中心
                    </template>
                        <MenuItem name="3-1" to='/homes/account'>账户中心</MenuItem>
                        <MenuItem name="3-2" to='/homes/billdown'>账单下载</MenuItem>
                </Submenu>
            </Menu>
                </Sider>
                <Layout style="background:#fff;height:90vh">
                    <router-view></router-view>
                </Layout>
            </Layout>
        </Layout>
  </div>
</template>

<script>
import Tophead from '@/components/Tophead/Tophead.vue';
  export default {
      provide(){
        return{
        chanlist:this.chan
      }
    },
    data() {
      return {
        dark:"dark",
        listname:"1-1",
        openname:['1','2','3'],
        opens:""
      }
    },
    mounted(){
        // this.$router.push({path:"/homes/merchant_order"})
    },
    methods: {
        chan(list,open){
            this.listname=list
           this.opens=[open]
        },
        change(name){
            this.listname=name
        }
    },
    name: 'Homes',
    components: {
      Tophead
    },
    watch: {
    openname() {
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      })
    }
  },
  }
</script>
<style scoped>
.width{width: 1570px;margin: 0 auto;}
.layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo{
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    width: 420px;
    margin: 0 auto;
    margin-right: 20px;
}
</style>